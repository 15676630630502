<script setup lang="ts">
const {
    isEnabled: isFooterEnabled,
} = useFooterState();

provide('isSubFlow', false);
</script>

<template>
    <div>
        <TheBaseLayout>
            <div
                class="
                    grid
                    h-full
                    grid-rows-[auto_min-content]
                    bg-background-1
                "
            >
                <div class="relative">
                    <slot name="logo">
                        <TheLogo />
                    </slot>
                    <slot name="progressDots">
                        <TheProgressDots />
                    </slot>
                    <slot name="main">
                        <TheNuxtPage />
                    </slot>
                </div>

                <div :class="isFooterEnabled ? 'h-[68px] md:h-[76px]' : 'h-0'">
                    <slot name="footer">
                        <TheFooter />
                    </slot>
                </div>
            </div>
        </TheBaseLayout>
    </div>
</template>
